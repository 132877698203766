import { Typography, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

import FileUpload from '../../../../../../../component/fileUpload';
import SelectWrapped from '../../../../../../../component/select';

class Banner extends PureComponent {
	static propTypes = {
		classes: PropTypes.shape({
			divContainer: PropTypes.string.isRequired,
			select: PropTypes.string.isRequired,
			uploadContainer: PropTypes.string.isRequired
		})
	};

	state = {
		checkedType: null,
		files: [],
		fundraisingBanner: ''
	};

	render() {
		const fileNames =
			this.state.files.map(file => {
				return file.name;
			}) || [];

		const { classes } = this.props;

		return (
			<Fragment>
				<Typography variant={'h6'}>Banner</Typography>
				<div className={classes.uploadContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.checkedType === 'importBanner'}
								onChange={this.handleChange('checkedType')}
								value="importBanner"
							/>
						}
						label={fileNames.join(', ')}
					/>
					{this.state.files.length === 0 && (
						<FileUpload
							handleCancel={this.handleCancel}
							handleDrop={this.handleDrop}
							helperText={'minimum: 400px high, 400px wide'}
						/>
					)}
				</div>
				<div className={classes.divContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.checkedType === 'selectedBanner'}
								onChange={this.handleChange('checkedType')}
								value="selectedBanner"
							/>
						}
						label={''}
					/>
					<SelectWrapped
						className={classes.select}
						label={'Select a banner...'}
						id={'fundraisingBanner'}
						value={this.state.fundraisingBanner}
						name={'fundraisingBanner'}
						onChange={this.changeCheckMark}
					>
						<option value="" />
						<option value={1}>Banner Option</option>
					</SelectWrapped>
				</div>
			</Fragment>
		);
	}

	handleDrop = files => {
		this.setState({
			files,
			checkedType: 'importBanner'
		});
	};

	handleCancel = () => {
		this.setState({
			files: []
		});
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	changeCheckMark = name => event => {
		this.setState({
			[name]: event.target.value,
			checkedType: 'selectedBanner'
		});
	};
}

const customStyles = theme => ({
	divContainer: {
		marginTop: '20px'
	},
	uploadContainer: {
		minHeight: '70px'
	},
	select: {
		minWidth: 200,
		margin: 0,
		width: 200,
		marginBottom: theme.spacing.unit * 5
	}
});

export default withStyles(customStyles)(Banner);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

class LoaderButton extends PureComponent {
	static propTypes = {
		...Button.propTypes,
		label: PropTypes.string.isRequired,
		classes: PropTypes.shape({
			buttonProgress: PropTypes.string,
			wrapper: PropTypes.string
		}),
		loading: PropTypes.bool,
		disabled: PropTypes.bool
	};

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	render() {
		const { classes, label, disabled, loading, ...rest } = this.props;

		return (
			<div className={classes.wrapper}>
				<Button {...rest} variant="contained" color="primary" disabled={disabled || loading}>
					{label}
				</Button>
				{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
			</div>
		);
	}
}

LoaderButton.propTypes = {};

const styles = theme => ({
	wrapper: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
		position: 'relative'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
});

export default withStyles(styles)(LoaderButton);

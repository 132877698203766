import get from 'lodash/get';
import React from 'react';

import Home from '../../page/public/home';
import SignIn from '../../page/public/signIn';
import SignUp from '../../page/public/signUp/index';
import Summary from '../../page/public/summary/index';
import Test from '../../page/public/test';
import ValidateToken from '../../page/public/validateToken';
import PublicContainer from '../containers/publicContainer';

export default Object.freeze({
	homePage: {
		route: '/',
		label: 'Home',
		getComponent: () => {
			return (
				<PublicContainer>
					<Home />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/';
		},
		siteMap: true
	},
	signIn: {
		route: '/sign-in',
		label: 'Sign In',
		getComponent: () => {
			return (
				<PublicContainer>
					<SignIn />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/sign-in';
		}
	},
	signUp: {
		route: '/sign-up',
		label: 'Sign Up',
		getComponent: () => {
			return (
				<PublicContainer>
					<SignUp />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/sign-up';
		},
		siteMap: true
	},
	signUpStep: {
		route: '/sign-up/:stepId',
		label: 'Sign Up',
		getComponent: props => {
			const stepId = get(props, 'match.params.stepId');
			return (
				<PublicContainer>
					<SignUp stepId={parseInt(stepId, 10)} />
				</PublicContainer>
			);
		},
		getRoute: stepId => {
			return `/sign-up/${stepId}`;
		},
		siteMap: true
	},
	summary: {
		route: '/summary',
		label: 'Summary',
		getComponent: () => {
			return (
				<PublicContainer>
					<Summary />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/summary';
		},
		siteMap: true
	},
	test: {
		route: '/test',
		label: 'Sign Up',
		getComponent: () => {
			return (
				<PublicContainer>
					<Test />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/test';
		},
		siteMap: true
	},
	validateToken: {
		route: '/validate',
		label: 'Validate Token',
		getComponent: () => {
			return (
				<PublicContainer>
					<ValidateToken />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/validate';
		}
	}
});

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

const HorizontalRule = props => {
	return <div className={props.classes.hr} />;
};

HorizontalRule.propTypes = {
	classes: PropTypes.shape({
		hr: PropTypes.string.isRequired
	}).isRequired
};

const customStyles = () => ({
	hr: {
		backgroundColor: '#AEAEAE',
		height: 1,
		margin: '45px 0 29px 0',
		width: '100%'
	}
});

export default withStyles(customStyles)(HorizontalRule);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import isEqual from 'lodash/isEqual';
import { Typography } from '@material-ui/core';

const NON_STATEFUL_TYPES = ['button', 'typography'];

class Form extends Component {
	static propTypes = {
		elements: PropTypes.array.isRequired,
		handleSubmit: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		const stateElements = props.elements.reduce((prev, element) => {
			if (NON_STATEFUL_TYPES.indexOf(element.type.toLowerCase()) > -1) {
				return prev;
			}
			return {
				...prev,
				[element.props.name]: element.props.default || ''
			};
		}, {});

		this.state = stateElements;
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return !(isEqual(nextProps, this.props) && isEqual(nextState, this.state));
	}

	componentWillUpdate(nextProps, nextState, nextContext) {
		console.log('updated');
	}

	render() {
		return (
			<form onSubmit={this.props.handleSubmit}>
				{this.props.elements.map(element => {
					if (element.type === 'textField') {
						return this.buildTextField(element);
					} else if (element.type === 'button') {
						return this.buildButton(element);
					} else if (element.type === 'typography') {
						return this.buildTypography(element);
					}
					return null;
				})}
			</form>
		);
	}

	buildTextField = element => {
		return <TextField key={element.key} {...element.props} onChange={this.handleChange(element.props.name)} />;
	};

	buildButton = element => {
		const { nonNullvalidation, submitForm } = element;

		const customElements = {};
		if (nonNullvalidation) {
			customElements.disabled = this.validateNonNull();
		}
		if (submitForm) {
			customElements.onClick = this.handleSubmit;
		}

		return (
			<Button {...customElements} {...element.props} key={element.key}>
				{element.label}
			</Button>
		);
	};

	buildTypography = element => {
		return <Typography {...element.props} />;
	};

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleSubmit = () => {
		this.props.handleSubmit(this.state);
	};

	validateNonNull = () => {
		const dataFilled = Object.values(this.state)
			.map(value => {
				return value && value !== '';
			})
			.filter(v => v);

		return !(dataFilled.length === Object.keys(this.state).length);
	};
}

export default Form;

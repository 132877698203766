import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../../component/header';
import LoaderButton from '../../../component/loaderButton';

class SignIn extends PureComponent {
	static propTypes = {
		classes: PropTypes.shape({
			container: PropTypes.string,
			header: PropTypes.string
		}),
		signIn: PropTypes.func,
		errorMessage: PropTypes.string,
		requestInFlight: PropTypes.bool.isRequired
	};

	state = {
		email: '',
		password: ''
	};

	render() {
		const { classes, errorMessage, requestInFlight } = this.props;
		return (
			<Fragment>
				<Header smallLogo={true} />
				<div className={classes.container}>
					<Typography variant={'h5'} className={classes.header}>
						Sign In
					</Typography>
					<div style={{ marginLeft: '50px', marginRight: '50px' }}>
						<TextField
							error={errorMessage && errorMessage !== ''}
							margin={'normal'}
							label={'Email'}
							fullWidth={true}
							value={this.state.email}
							onChange={this.handleChange('email')}
						/>
						<TextField
							error={errorMessage && errorMessage !== ''}
							value={this.state.password}
							margin={'normal'}
							label={'Password'}
							fullWidth={true}
							onChange={this.handleChange('password')}
						/>
						{errorMessage && <Typography variant={'body2'}>{errorMessage}</Typography>}
						<div style={{ paddingTop: '25px' }}>
							<LoaderButton
								loading={requestInFlight}
								disabled={this.authenticateDisabled()}
								onClick={this.handleAuthenticate}
								label={'Sign In'}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	authenticateDisabled = () => {
		return this.state.email === '' || this.state.password === '';
	};

	handleAuthenticate = () => {
		this.props.signIn({
			username: this.state.email,
			password: this.state.password
		});
	};
}

const customStyles = theme => ({
	container: {
		margin: 'auto',
		width: '70%',
		border: '1px solid #f2f2f2',
		padding: '15px',
		borderRadius: '5px',
		backgroundColor: '#f2f2f2'
	},
	header: {
		textAlign: 'center'
	},
	formControl: {
		margin: theme.spacing.unit
	}
});

export default flow([
	connect(
		null,
		null
	),
	withRouter,
	withStyles(customStyles)
])(SignIn);

import React, { Component, Fragment } from 'react';

import publicRoutes from '../../../core/routes/public';
import Button from '../../../component/button/button';
import FundraisingCarousel from '../../../component/fundraisingCarousel';
import styles from './home.module.scss';
import CircleNav from '../../../component/circleNav';
import Header from '../../../component/header';

class Home extends Component {
	render() {
		return (
			<Fragment>
				<Header />
				<div className={styles.container}>
					<FundraisingCarousel />
					<div className={styles.fundraisers}>View all fundraisers</div>
					<div className={styles.section}>
						<Button
							label={'Start a fundraiser'}
							containerClass={styles.buttonContainer}
							location={publicRoutes.signUp.getRoute()}
						/>
					</div>
					<div className={styles.navigationContainer}>
						<CircleNav
							actions={[
								{ color: 'Red', label: 'Why use 4giving', element: <i className="fas fa-question" /> },
								{ color: 'Blue', label: '4giving map', element: <i className="fas fa-at" /> },
								{ color: 'Orange', label: 'Success Stories', element: <i className="fas fa-thumbs-up" /> }
							]}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Home;

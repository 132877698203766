import { useState } from 'react';

interface FormInput {
	onChange(e: React.ChangeEvent<HTMLInputElement>): void;
	value: any;
}

export const isSet = (v: number | string | undefined): boolean => {
	if (!v) {
		return false;
	}
	return !!(v || v !== '');
};

export const useFormInput = (initialValue: string): FormInput => {
	const [value, setValue] = useState(initialValue);

	function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
		setValue(e.target.value);
	}

	return {
		value,
		onChange: handleChange
	};
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styles from './linkList.module.scss';

class LinkList extends PureComponent {
	static propTypes = {
		containerStyle: PropTypes.number,
		elements: PropTypes.arrayOf(PropTypes.element).isRequired
	};

	render() {
		const { elements, containerStyle } = this.props;

		return (
			<ul className={styles.linkList} style={containerStyle}>
				{elements.map((element, i) => (
					<li key={i}>{element}</li>
				))}
			</ul>
		);
	}
}

export default LinkList;

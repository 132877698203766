import { Fundraiser } from '../../../../../store/modules/fundraiser/model';
import { isSet } from '../../../../../utility/formHelpers';

export interface FundraiserSettingValidationErrors {
	category?: string;
	zipCode?: string;
}

export interface FundraiserPayload {
	goal?: number;
	duration?: string;
	startDate?: string;
	endDate?: string;
}

export const validateSettings = ({ zipCode, category }: Fundraiser): FundraiserSettingValidationErrors => {
	const errors: FundraiserSettingValidationErrors = {};
	if (!isSet(zipCode)) {
		errors.category = 'Please enter a title';
	}
	if (!isSet(category)) {
		errors.zipCode = 'Please enter a zipcode';
	}

	return errors;
};

import React, { PureComponent } from 'react';

import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';

class SelectWrapped extends PureComponent {
	static propTypes = {
		error: PropTypes.bool,
		className: PropTypes.string,
		containerClass: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
		errorText: PropTypes.string,
		grid: PropTypes.object,
		id: PropTypes.string,
		label: PropTypes.string,
		name: PropTypes.string,
		onChange: PropTypes.func,
		value: PropTypes.string
	};

	render() {
		const { classes, containerClass, children, grid, label, id, name, onChange, value, error, errorText } = this.props;

		const SelectComponent = (
			<FormControl className={[classes.formControl, this.props.className].join(' ')} error={error}>
				<InputLabel htmlFor={id}>{label}</InputLabel>
				<Select
					native
					value={value}
					onChange={name ? onChange(name) : onChange}
					inputProps={{
						name,
						id
					}}
				>
					{children}
				</Select>
				{error && <FormHelperText>{errorText}</FormHelperText>}
			</FormControl>
		);

		const WrappedComponent = containerClass ? <div className={containerClass}>{SelectComponent}</div> : SelectComponent;

		const GridComponent = grid ? (
			<Grid item {...grid}>
				{WrappedComponent}
			</Grid>
		) : (
			WrappedComponent
		);

		return GridComponent;
	}
}

const customStyles = theme => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 250
	}
});

export default withStyles(customStyles)(SelectWrapped);

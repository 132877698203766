import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import LinkList from '../linkList';
import styles from './footer.module.scss';

class Footer extends PureComponent {
	render() {
		return (
			<div className={styles.container}>
				<LinkList
					elements={[
						<Link key="privacy" to={'/privacy'}>
							Privacy
						</Link>,
						<Link key="terms" to={'/terms'}>
							Terms
						</Link>
					]}
				/>
			</div>
		);
	}
}

export default Footer;

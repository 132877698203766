import { Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import flow from 'lodash/flow';
import moment from 'moment';
import React, { Fragment, ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import NumberFormatCustom from '../../../../../component/inputNumberFormat';
import SelectWrapped from '../../../../../component/select';
import WizardButtonGroup from '../../../../../component/wizardButtonGroup/wizardButtonGroup';
import { AppState } from '../../../../../store';
import fundraiserMembers from '../../../../../store/modules/fundraiser';
import { FundraiserLength, getFundraiserLengthFromString } from '../../../../../store/modules/fundraiser/constants';
import { Fundraiser } from '../../../../../store/modules/fundraiser/model';
import { isSet, useFormInput } from '../../../../../utility/formHelpers';
import { FundraiserGoalValidationErrors } from './interfaces';
import { prepareFundraiser, validateFundraiser } from './validations';

interface Props {
	classes: any;
	fundraiser: Fundraiser;
	handleBack(): void;
	handleNext(): void;
	saveLocalFundraiser(fundraiser: Fundraiser): void;
}

const Goals = (props: Props): ReactElement => {
	const { classes, fundraiser, handleBack, handleNext, saveLocalFundraiser } = props;

	const [errors, setErrorValue] = useState<FundraiserGoalValidationErrors>({});
	const goal = useFormInput(`${fundraiser.goal}`);
	const duration = useFormInput(fundraiser.duration || FundraiserLength.ONGOING);
	const startDate = useFormInput(
		(fundraiser.startDate && moment(fundraiser.startDate).format('YYYY-MM-DDTHH:mm')) || moment().format('YYYY-MM-DDTHH:mm')
	);
	const endDate = useFormInput(
		(fundraiser.endDate && moment(fundraiser.endDate).format('YYYY-MM-DDTHH:mm')) ||
			moment()
				.add(1, 'months')
				.format('YYYY-MM-DDTHH:mm')
	);

	function validateAndSaveFundraiser(): void {
		const fundraiser: Fundraiser = prepareFundraiser({
			duration: getFundraiserLengthFromString(duration.value),
			goal: parseInt(goal.value, 10),
			startDate: new Date(startDate.value),
			endDate: new Date(endDate.value)
		});

		const errors: FundraiserGoalValidationErrors = validateFundraiser(fundraiser);
		if (Object.keys(errors).length === 0) {
			saveLocalFundraiser(fundraiser);
			handleNext();
		} else {
			setErrorValue(errors);
		}
	}

	return (
		<div className={classes.section}>
			<TextField
				{...goal}
				className={classes.textField}
				label="Enter fundraising goal"
				InputProps={{
					inputComponent: NumberFormatCustom,
					classes: {
						input: classes.resize
					}
				}}
				InputLabelProps={{
					classes: {
						root: classes.resize
					}
				}}
				error={isSet(errors.goal)}
				helperText={errors.goal}
			/>

			<SelectWrapped
				className={classes.select}
				label={'Choose start and end time'}
				grid={{ xs: 12 }}
				id={'fundraising-durationType'}
				{...duration}
				error={isSet(errors.duration)}
				errorText={errors.duration}
			>
				<option value={fundraiserMembers.constants.FundraiserLength.ONGOING}>Ongoing</option>
				<option value={fundraiserMembers.constants.FundraiserLength.START_AND_END}>Start/end date & time</option>
			</SelectWrapped>
			{duration.value === fundraiserMembers.constants.FundraiserLength.START_AND_END && (
				<Fragment>
					<TextField
						id="datetime-local"
						label="Start Date"
						type="datetime-local"
						className={classes.textFieldSplit}
						InputLabelProps={{
							shrink: true
						}}
						{...startDate}
						error={isSet(errors.startDate)}
						helperText={errors.startDate}
					/>

					<TextField
						id="datetime-local"
						label="End Date"
						type="datetime-local"
						className={classes.textFieldSplit}
						InputLabelProps={{
							shrink: true
						}}
						{...endDate}
						error={isSet(errors.endDate)}
						helperText={errors.endDate}
					/>
				</Fragment>
			)}
			<WizardButtonGroup advanceButtonLabel={'Next'} handleBack={handleBack} handleNext={validateAndSaveFundraiser} />
		</div>
	);
};

const mapStateToProps = (state: AppState): any => {
	return {
		fundraiser: fundraiserMembers.selectors.selectFundraiserData(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			saveLocalFundraiser: fundraiserMembers.actions.setFundraiserValues
		},
		dispatch
	);
};

const customStyles = (theme: Theme): any => ({
	container: {
		margin: '0 auto',
		width: '100%'
	},
	select: {
		marginBottom: theme.spacing.unit * 5
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: '100%',
		marginBottom: theme.spacing.unit * 5
	},
	resize: {
		fontSize: 25
	},
	textFieldSplit: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: '45%',
		'&:last-of-type': {
			float: 'right'
		}
	},
	section: {
		padding: '35px 80px',
		backgroundColor: '#F5FFF9'
	},
	actionsContainer: {
		marginTop: theme.spacing.unit * 4,
		marginBottom: theme.spacing.unit * 2
	}
});

export default flow([
	withStyles(customStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Goals);

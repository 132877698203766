import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

class Group extends PureComponent {
	static propTypes = {
		classes: PropTypes.shape({
			headerContainer: PropTypes.string
		}).isRequired,
		children: PropTypes.array,
		bgColor: PropTypes.string,
		gutterTop: PropTypes.bool,
		title: PropTypes.string,
		location: PropTypes.string
	};

	render() {
		const { children, classes, bgColor, gutterTop, title, location } = this.props;

		const containerClasses = [classes.headerContainer];
		if (gutterTop) {
			containerClasses.push(classes.headerGutterTop);
		}

		return (
			<Fragment>
				<div className={containerClasses.join(' ')} style={{ backgroundColor: bgColor }}>
					<Typography className={classes.headerItem} variant={'h6'}>
						{title}
					</Typography>
					<Link component={RouterLink} to={location}>
						edit
					</Link>
				</div>
				{children}
			</Fragment>
		);
	}
}

const styles = {
	headerContainer: {
		borderBottom: '1px solid #AAAAAA',
		width: '100%',
		padding: '5px 10px',
		marginBottom: '15px'
	},
	headerItem: {
		display: 'inline-block',
		paddingRight: 15
	},
	headerGutterTop: {
		marginTop: '15px'
	}
};

export default withStyles(styles)(Group);

import { Reducer } from 'redux';

import * as AuthService from '../../../utility/AuthService';
import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { AuthState } from './model';

export const initialState: AuthState = {
	authData: {
		isAuthenticated: !AuthService.isTokenExpired()
	},
	status: {
		error: undefined,
		inFlight: false,
		success: false
	}
};

const reducer: Reducer<AuthState> = (state = initialState, action: Action): AuthState => {
	switch (action.type) {
		case t.LOGIN_REQUEST:
			return {
				...state,
				status: {
					...state.status,
					inFlight: true,
					error: undefined
				}
			};
		case t.LOGIN_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				authData: {
					isAuthenticated: true
				}
			};
		case t.LOGIN_ERROR:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					error: action.error
				},
				authData: {
					isAuthenticated: true
				}
			};
		case t.LOGOUT_SUCCESS:
			return {
				...state,
				authData: {
					isAuthenticated: false
				}
			};
		default:
			return state;
	}
};

export default reducer;

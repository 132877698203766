import { Theme } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import withStyles from '@material-ui/core/styles/withStyles';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement, useState } from 'react';

import Header from '../../../component/header';
import Branding from './steps/branding';
import Goals from './steps/goals';
import Settings from './steps/settings';

interface Props {
	activeStep?: number;
	classes: any;
	stepId: number;
}

const SignUp = (props: Props): ReactElement => {
	const { classes, stepId } = props;
	const [activeStep, setStep] = useState<number>(stepId);

	const handleNext = (): void => {
		setStep(activeStep + 1);
	};

	const handleBack = (): void => {
		setStep(activeStep - 1);
	};

	return (
		<Fragment>
			<Header smallLogo={true} />
			<Stepper activeStep={activeStep} orientation="vertical">
				<Step key={1}>
					<StepLabel
						StepIconProps={{
							classes: {
								active: classes.goalColor,
								completed: classes.goalColor
							}
						}}
					>
						Goal & duration
					</StepLabel>
					<StepContent className={classes.stepContent}>
						<Goals handleNext={handleNext} handleBack={handleBack} />
					</StepContent>
				</Step>
				<Step key={2}>
					<StepLabel
						StepIconProps={{
							classes: {
								active: classes.brandingColor,
								completed: classes.brandingColor
							}
						}}
					>
						Title, description & branding
					</StepLabel>
					<StepContent className={classes.stepContent}>
						<Branding handleNext={handleNext} handleBack={handleBack} />
					</StepContent>
				</Step>
				<Step key={3}>
					<StepLabel
						StepIconProps={{
							classes: {
								active: classes.settingsColor,
								completed: classes.settingsColor
							}
						}}
					>
						Settings
					</StepLabel>
					<StepContent className={classes.stepContent}>
						<Settings activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
					</StepContent>
				</Step>
			</Stepper>
		</Fragment>
	);
};

SignUp.defaultProps = {
	stepId: 0
};

const customStyles = (theme: Theme): any => ({
	root: {
		width: '90%'
	},
	button: {
		marginTop: theme.spacing.unit,
		marginRight: theme.spacing.unit
	},
	actionsContainer: {
		marginBottom: theme.spacing.unit * 2
	},
	resetContainer: {
		padding: theme.spacing.unit * 3
	},
	stepContent: {
		paddingLeft: 0
	},
	goalColor: {
		color: '#77C49E !important'
	},
	brandingColor: {
		color: '#E7C313 !important'
	},
	settingsColor: {
		color: '#E46003 !important'
	}
});

export default flow([withStyles(customStyles)])(SignUp);

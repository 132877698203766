import './global.scss';

import CssBaseline from '@material-ui/core/CssBaseline';
import flow from 'lodash/flow';
import React, { Component, Fragment, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../store';
import authMembers from '../store/modules/auth';
import * as AuthService from '../utility/AuthService';

interface Props {
	loginError(error: any): void;
	loginSuccess(): void;
}

class AppContainer extends Component<Props> {
	public componentWillMount(): void {
		const { loginError, loginSuccess } = this.props;
		// Add callback for lock's `authenticated` event
		AuthService.lock.on('authenticated', authResult => {
			AuthService.lock.getUserInfo(
				authResult.accessToken,
				(error): void => {
					if (error) {
						loginError(error);
					}
					AuthService.setToken(authResult.idToken); // static method
					loginSuccess();
					AuthService.lock.hide();
				}
			);
		});
		// Add callback for lock's `authorization_error` event
		AuthService.lock.on(
			'authorization_error',
			(error): void => {
				loginError(error);
			}
		);
	}

	public render(): ReactElement {
		return (
			<Fragment>
				<CssBaseline />
				{this.props.children}
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			loginError: authMembers.actions.loginError,
			loginSuccess: authMembers.actions.loginSuccess
		},
		dispatch
	);
};

export default flow([
	connect(
		null,
		mapDispatchToProps
	)
])(AppContainer);

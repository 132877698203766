import React from 'react';

import Dashboard from '../../page/private/dashboard/index';

export default Object.freeze({
	dashboard: {
		route: '/dashboard',
		label: 'Dashboard',
		getComponent: () => {
			return <Dashboard />;
		},
		getRoute: () => {
			return '/dashboard';
		}
	}
});

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import CircleAction from '../circleAction';

const customStyles = () => ({
	gridContainer: {
		padding: '20px 0'
	}
});

class CircleNav extends PureComponent {
	static propTypes = {
		actions: PropTypes.arrayOf(
			PropTypes.shape({
				color: PropTypes.oneOf(['Red', 'Yellow', 'Blue', 'Orange', 'Green']).isRequired,
				label: PropTypes.string.isRequired,
				selected: PropTypes.bool,
				element: PropTypes.element
			}).isRequired
		).isRequired,
		classes: PropTypes.shape({
			gridContainer: PropTypes.string
		})
	};

	render() {
		const { classes, actions } = this.props;

		return (
			<Grid container className={classes.gridContainer}>
				{actions.map(({ color, label, selected, element }, i) => {
					return (
						<CircleAction color={color} label={label} selected={selected} key={i}>
							{element}
						</CircleAction>
					);
				})}
			</Grid>
		);
	}
}

export default withStyles(customStyles)(CircleNav);

import noop from 'lodash/noop';

import { ServiceState } from './model';

export const initialState: ServiceState = {
	getAccessToken: () => null,
	clearAccessToken: noop,
	executeApi: () => Promise.resolve(),
	setAccessToken: noop,
	hasAccessToken: () => false
};

const service = (state = initialState): ServiceState => {
	return state;
};

export default service;

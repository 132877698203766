import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import styles from './button.module.scss';

class Button extends PureComponent {
	static propTypes = {
		label: PropTypes.string.isRequired,
		location: PropTypes.func.isRequired,
		containerClass: PropTypes.number
	};

	render() {
		const containerClass = [styles.button];
		if (this.props.containerClass) {
			containerClass.push(this.props.containerClass);
		}

		return (
			<Route
				render={({ history }) => (
					<div
						className={containerClass.join(' ')}
						onClick={() => {
							history.push(this.props.location);
						}}
					>
						<div className={styles.plus}>
							<div className={styles.plusSign}>+</div>
						</div>
						<div className={styles.text}>{this.props.label}</div>
					</div>
				)}
			/>
		);
	}
}

Button.propTypes = {};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Button);

import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import FileUpload from '../../../../../../../component/fileUpload';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Logo extends PureComponent {
	static propTypes = {
		classes: PropTypes.shape({
			divContainer: PropTypes.string.isRequired,
			uploadContainer: PropTypes.string.isRequired
		})
	};
	state = {
		checkedType: null,
		files: []
	};

	render() {
		const fileNames =
			this.state.files.map(file => {
				return file.name;
			}) || [];

		const { classes } = this.props;

		return (
			<Fragment>
				<Typography variant={'h6'}>Logo</Typography>
				<div className={classes.uploadContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.checkedType === 'logoImage'}
								onChange={this.handleChange('checkedType')}
								value="logoImage"
							/>
						}
						label={fileNames.join(', ')}
					/>

					{this.state.files.length === 0 && (
						<FileUpload
							handleCancel={this.handleCancel}
							handleDrop={this.handleDrop}
							helperText={'minimum: 400px high, 400px wide'}
						/>
					)}
				</div>
				<div className={classes.divContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.checkedType === 'initials'}
								onChange={this.handleChange('checkedType')}
								value="initials"
							/>
						}
						label={''}
					/>
					<TextField label="Use Initials" />
				</div>
			</Fragment>
		);
	}

	handleDrop = files => {
		this.setState({
			files,
			checkedType: 'logoImage'
		});
	};

	handleCancel = () => {
		this.setState({
			files: []
		});
	};

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};
}

const customStyles = theme => ({
	divContainer: {
		marginTop: '20px'
	},
	uploadContainer: {
		minHeight: '70px'
	}
});

export default withStyles(customStyles)(Logo);

import { Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import flow from 'lodash/flow';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import WizardButtonGroup from '../../../../../component/wizardButtonGroup/wizardButtonGroup';
import { AppState } from '../../../../../store';
import fundraiserMembers from '../../../../../store/modules/fundraiser';
import { Fundraiser } from '../../../../../store/modules/fundraiser/model';
import { isSet, useFormInput } from '../../../../../utility/formHelpers';
import Banner from './materials/banner';
import Logo from './materials/logo';
import { FundraiserBrandingValidationErrors, validateBranding } from './validations';

interface Props {
	classes: any;
	fundraiser: Fundraiser;
	handleBack(): void;
	handleNext(): void;
	saveLocalFundraiser(fundraiser: Fundraiser): void;
}

const Branding = (props: Props): ReactElement => {
	const { classes, handleBack, handleNext, fundraiser, saveLocalFundraiser } = props;
	const [errors, setErrorValue] = useState<FundraiserBrandingValidationErrors>({});
	const title = useFormInput(fundraiser.title || '');
	const description = useFormInput(fundraiser.description || '');

	function validateAndSaveFundraiser(): void {
		const validateFundraiser: Fundraiser = {
			title: title.value,
			description: description.value
		};
		const errors: FundraiserBrandingValidationErrors = validateBranding(validateFundraiser);
		if (Object.keys(errors).length === 0) {
			saveLocalFundraiser(validateFundraiser);
			handleNext();
		} else {
			setErrorValue(errors);
		}
	}

	return (
		<div className={classes.section}>
			<TextField
				className={classes.textField}
				label="Fundraiser Title"
				{...title}
				error={isSet(errors.title)}
				helperText={errors.title}
			/>
			<TextField
				className={classes.textField}
				label="Fundraiser Description"
				{...description}
				error={isSet(errors.description)}
				helperText={errors.description}
			/>
			<Grid container spacing={24}>
				<Grid item xs={12} md={6}>
					<Logo />
				</Grid>
				<Grid item xs={12} md={6}>
					<Banner />
				</Grid>
			</Grid>
			<WizardButtonGroup advanceButtonLabel={'Next'} handleBack={handleBack} handleNext={validateAndSaveFundraiser} />
		</div>
	);
};

const customStyles = (theme: Theme): any => ({
	container: {
		margin: '0 auto',
		width: '100%'
	},
	select: {
		marginBottom: theme.spacing.unit * 5
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: '100%',
		marginBottom: theme.spacing.unit * 5
	},
	resize: {
		fontSize: 50
	},
	textFieldSplit: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: '45%',
		'&:last-of-type': {
			float: 'right'
		}
	},
	section: {
		padding: '35px 80px',
		backgroundColor: '#FEFBF1'
	},
	actionsContainer: {
		marginTop: theme.spacing.unit * 4,
		marginBottom: theme.spacing.unit * 2
	}
});

const mapStateToProps = (state: AppState): any => {
	return {
		fundraiser: fundraiserMembers.selectors.selectFundraiserData(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			saveLocalFundraiser: fundraiserMembers.actions.setFundraiserValues
		},
		dispatch
	);
};

export default flow([
	withStyles(customStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Branding);

import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../..';
import { METHODS } from '../service/model';
import { selectRequestor } from '../service/selectors';
import {
	fundraiserFailure,
	fundraiserListFailure,
	fundraiserListRequest,
	fundraiserListSuccess,
	fundraiserRequest,
	fundraiserSuccess
} from './actions';
import { Fundraiser } from './model';
import { selectFundraiserRequestInFlight } from './selectors';

export const saveFundraiser = (fundraiser: Fundraiser): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectFundraiserRequestInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);
		dispatch(fundraiserRequest());
		try {
			const response: AxiosResponse<Fundraiser> = await requestor.executeApi({
				endpoint: '/fundraiser',
				method: METHODS.POST,
				data: fundraiser
			});
			dispatch(fundraiserSuccess(response.data));
		} catch (e) {
			dispatch(fundraiserFailure(e));
		}
	};
};

export const getFundraisers = (): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectFundraiserRequestInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);

		dispatch(fundraiserListRequest());
		try {
			const response: AxiosResponse<Fundraiser[]> = await requestor.executeApi({
				endpoint: '/fundraiser',
				method: METHODS.GET
			});
			dispatch(fundraiserListSuccess(response.data));
		} catch (e) {
			dispatch(fundraiserListFailure(e));
		}
	};
};

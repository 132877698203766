import { History } from 'history';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Header from '../../../component/header';
import HorizontalRule from '../../../component/horizontalRule/horizontalRule';
import LoaderButton from '../../../component/loaderButton';
import privateRoutes from '../../../core/routes/private';
import publicRoutes from '../../../core/routes/public';
import { AppState } from '../../../store';
import authMembers from '../../../store/modules/auth';
import fundraiserMembers from '../../../store/modules/fundraiser';
import { Fundraiser } from '../../../store/modules/fundraiser/model';
import { login, logout } from '../../../utility/AuthService';
import Group from './group';
import Item from './item';

interface Props {
	isAuthenticated: boolean;
	fundraiser: Fundraiser;
	history: History;
	loginRequest(): void;
	logoutSuccess(): void;
	fundraiserSuccess: boolean;
	saveFundraiser(fundraiser: Fundraiser): void;
}

const Summary = (props: Props): ReactElement => {
	const { fundraiserSuccess, isAuthenticated, loginRequest, logoutSuccess, saveFundraiser, history, fundraiser } = props;

	useEffect((): void => {
		if (!fundraiser.goal) {
			history.push(publicRoutes.signUpStep.getRoute(0));
		}
	}, []);

	useEffect((): void => {
		if (fundraiserSuccess) {
			history.push(privateRoutes.dashboard.route);
		}
	}, [fundraiserSuccess]);

	const authenticate = (): void => {
		loginRequest();
		login();
	};

	const logoutRequest = (): void => {
		logoutSuccess();
		logout();
	};

	const startFundraiser = (): void => {
		saveFundraiser(fundraiser);
	};

	return (
		<div>
			<Header smallLogo={true} />
			<Group bgColor={'#F8FCF9'} location={publicRoutes.signUpStep.getRoute(0)} title={'Goal & duration'}>
				<Item label={'Goal'} description={`${fundraiser.goal}`} />
				<Item label={'Duration'} description={'March 2, 2019 12:00 AM - March 22, 2019 12:00 AM'} />
			</Group>
			<Group
				gutterTop
				bgColor={'#FDFCF4'}
				location={publicRoutes.signUpStep.getRoute(1)}
				title={'Title, description & branding'}
			>
				<Item label={'Title'} description={fundraiser.title} />
				<Item label={'Duration'} description={fundraiser.description} />
				<Item
					label={'Branding'}
					description={
						'The Animal Connections\'s Drive helps support this and that.  Generally, it helps more "this" than "that" but sometimes it helps "that" more than "this". It just depends on this and that.'
					}
				/>
			</Group>
			<Group gutterTop bgColor={'#FDF8F3'} location={publicRoutes.signUpStep.getRoute(2)} title={'Settings'}>
				<Item label={'Fundraiser category'} labelMDGridSize={3} description={fundraiser.category} />
				<Item label={"Fundraiser's zip code"} labelMDGridSize={3} description={fundraiser.zipCode} />
			</Group>
			<HorizontalRule />
			{!isAuthenticated && (
				<LoaderButton bsStyle="primary" className="btn-margin" onClick={authenticate} label={'Log In'} />
			)}
			{isAuthenticated && (
				<Fragment>
					<LoaderButton
						bsStyle="primary"
						className="btn-margin"
						onClick={startFundraiser}
						label={'Start my fundraiser'}
					/>
					<LoaderButton bsStyle="primary" className="btn-margin" onClick={logoutRequest} label={'Log out'} />
				</Fragment>
			)}
			<HorizontalRule />
		</div>
	);
};

const mapStateToProps = (state: AppState): any => {
	return {
		isAuthenticated: authMembers.selectors.selectIsAuthenticated(state),
		fundraiser: fundraiserMembers.selectors.selectFundraiserData(state),
		fundraiserSuccess: fundraiserMembers.selectors.selectFundraiserRequestSuccess(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			loginRequest: authMembers.actions.loginRequest,
			logoutSuccess: authMembers.actions.logoutSuccess,
			saveFundraiser: fundraiserMembers.thunks.saveFundraiser
		},
		dispatch
	);
};

export default flow([
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Summary);

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class Item extends PureComponent {
	static propTypes = {
		classes: PropTypes.shape({
			itemContainer: PropTypes.string
		}).isRequired,
		label: PropTypes.string,
		labelMDGridSize: PropTypes.number,
		description: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element])
	};

	render() {
		const { classes, label, labelMDGridSize, description } = this.props;

		const labelMd = labelMDGridSize || 2;
		const descriptionMd = 12 - labelMd;

		return (
			<Grid container className={classes.itemContainer}>
				<Grid sm={12} md={labelMd} item className={classes.labelContainer}>
					<Typography className={classes.label} variant={'body1'}>
						{label}
					</Typography>
				</Grid>
				<Grid item sm={12} md={descriptionMd}>
					<Typography variant={'body1'}>{description}</Typography>
				</Grid>
			</Grid>
		);
	}
}

const styles = {
	itemContainer: {
		padding: '5px 10px'
	},
	label: {
		color: '#818181'
	},
	labelContainer: {}
};

export default withStyles(styles)(Item);

export const fundraisingCategories = [
	{ value: 'Animals' },
	{ value: 'Arts, Culture, Humanities' },
	{ value: 'Community Development' },
	{ value: 'Education' },
	{ value: 'Environment' },
	{ value: 'Health' },
	{ value: 'Human and Civil Rights' },
	{ value: 'Human Services' },
	{ value: 'International' },
	{ value: 'Research and Public Policy' },
	{ value: 'Religion' }
];

import moment from 'moment';

import { FundraiserLength } from '../../../../../store/modules/fundraiser/constants';
import { Fundraiser } from '../../../../../store/modules/fundraiser/model';
import { isSet } from '../../../../../utility/formHelpers';
import { FundraiserGoalValidationErrors } from './interfaces';

//@todo: Add test cases.

export const validateFundraiser = ({ goal, duration, startDate, endDate }: Fundraiser): FundraiserGoalValidationErrors => {
	const errors: FundraiserGoalValidationErrors = {};
	if (!isSet(goal)) {
		errors.goal = 'Please enter a fundraising goal';
	}
	if (!isSet(duration)) {
		errors.duration = 'Please select a duration for your fundraising goal';
	}

	if (duration === FundraiserLength.START_AND_END) {
		if (
			startDate &&
			moment()
				.subtract(1, 'days')
				.isAfter(startDate)
		) {
			errors.startDate = "Start date can't be in the past.";
		}

		if (startDate && endDate && startDate > endDate) {
			errors.endDate = 'End date must be after start.';
		} else if (
			moment()
				.add(1, 'days')
				.isSameOrBefore(startDate)
		) {
			errors.endDate = 'Fundraiser must be at least one day long';
		}
	}

	return errors;
};

export const prepareFundraiser = (fundraiser: Fundraiser): Fundraiser => {
	if (fundraiser.duration === FundraiserLength.START_AND_END) {
		return fundraiser;
	}
	return {
		goal: fundraiser.goal,
		duration: fundraiser.duration
	};
};

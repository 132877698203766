export enum METHODS {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	DELETE = 'delete'
}

export interface ExecuteAPIInterface {
	endpoint: string;
	method: METHODS;
	data?: any;
}

export interface ServiceState {
	getAccessToken(): string | null;
	clearAccessToken(): void;
	executeApi(executeApi: ExecuteAPIInterface): Promise<any>;
	setAccessToken(token: string): void;
	hasAccessToken(): boolean;
}

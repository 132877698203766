export enum FundraiserLength {
	ONGOING = 'ongoing',
	START_AND_END = 'startAndEnd'
}

export const getFundraiserLengthFromString = (enumValue: string): FundraiserLength => {
	if (enumValue === FundraiserLength.START_AND_END) {
		return FundraiserLength.START_AND_END;
	}
	return FundraiserLength.ONGOING;
};

export const STATE_KEY = 'fundraiser';

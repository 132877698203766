import { Card, CardContent, CardHeader, Grid, GridList, GridListTile, Paper, Typography, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import LoaderButton from '../../../component/loaderButton';
import { AppState } from '../../../store';
import authMembers from '../../../store/modules/auth';
import fundraiserMembers from '../../../store/modules/fundraiser';
import { Fundraiser } from '../../../store/modules/fundraiser/model';
import { logout } from '../../../utility/AuthService';

interface Props {
	classes: any;
	fundraiserList: Fundraiser[];
	getFundraisers(): void;
	logoutSuccess(): void;
}

const Dashboard = (props: Props): ReactElement => {
	useEffect((): void => {
		props.getFundraisers();
	}, []);

	const logoutRequest = (): void => {
		props.logoutSuccess();
		logout();
	};

	return (
		<div className={props.classes.root}>
			<Grid container spacing={24}>
				<Grid item xs={12}>
					<Paper className={props.classes.paper}>
						<Typography variant={'h3'}>Fundraisers</Typography>
						<LoaderButton bsStyle="primary" className="btn-margin" onClick={logoutRequest} label={'Log out'} />
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<GridList cols={3}>
						{props.fundraiserList.map(
							(myFundraiser: Fundraiser, i: number): ReactElement => (
								<GridListTile key={i}>
									<Card className={props.classes.card}>
										<CardHeader title={myFundraiser.title} subheader={`Type: ${myFundraiser.duration}`} />
										<CardContent>
											<Typography color="textSecondary">Goal: ${myFundraiser.goal}</Typography>
											<Typography>{myFundraiser.description}</Typography>
										</CardContent>
									</Card>
								</GridListTile>
							)
						)}
					</GridList>
				</Grid>
			</Grid>
		</div>
	);
};

const styles = (theme: any): any => ({
	root: {
		backgroundColor: '#414141',
		padding: '25px',
		flexGrow: 1
	},
	card: {
		maxWidth: 400
	},
	paper: {
		padding: theme.spacing.unit * 2,
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200
	}
});

const mapStateToProps = (state: AppState): any => {
	return {
		fundraiserList: fundraiserMembers.selectors.selectFundraisers(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			getFundraisers: fundraiserMembers.thunks.getFundraisers,
			logoutSuccess: authMembers.actions.logoutSuccess
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Dashboard);

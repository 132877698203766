import axios, { AxiosPromise } from 'axios';

import { ExecuteAPIInterface } from '../store/modules/service/model';
import { RequestOptionsInterface } from './requestInterface';

export const ACCESS_TOKEN = 'id_token';

const BUILD_TYPES = {
	STAGING: 'staging',
	PRODUCTION: 'production',
	LOCAL: 'local'
};
const getDomain = (): string => {
	if (window.location.host.indexOf('dev') > -1) {
		return BUILD_TYPES.LOCAL;
	} else if (window.location.host.indexOf('localhost') > -1) {
		return BUILD_TYPES.LOCAL;
	} else if (window.location.host.indexOf('beta') > -1) {
		return BUILD_TYPES.STAGING;
	} else if (window.location.host.indexOf('prod') > -1) {
		return BUILD_TYPES.PRODUCTION;
	}
	return BUILD_TYPES.PRODUCTION;
};

const getApiEndpoint = (): string => {
	switch (getDomain()) {
		case BUILD_TYPES.PRODUCTION:
			return 'https://prod--services.4givingapp.net';
		case BUILD_TYPES.STAGING:
			return 'https://staging--services.4givingapp.net';
		default:
			return 'http://dev.4givingapp.net:3000';
	}
};

const BASE_URL = getApiEndpoint();

const getAccessToken = (): string | null => {
	return localStorage.getItem(ACCESS_TOKEN);
};

export const clearAccessToken = (): void => {
	localStorage.removeItem(ACCESS_TOKEN);
};

export const setAccessToken = (): void => {
	// localStorage.setItem(ACCESS_TOKEN, token);
};

export const hasAccessToken = (): boolean => {
	const hasToken = getAccessToken();
	return !!(hasToken && hasToken !== 'undefined');
};
export const executeApi = (executeAPIInterface: ExecuteAPIInterface): AxiosPromise => {
	const token = getAccessToken();
	const requestOptions: RequestOptionsInterface = {
		method: executeAPIInterface.method.toLowerCase(),
		url: `${BASE_URL}${executeAPIInterface.endpoint}`
	};

	if (token) {
		requestOptions.headers = {
			Authorization: `Bearer ${token}`
		};
	}

	if (executeAPIInterface.data) {
		requestOptions.data = executeAPIInterface.data;
	}

	return axios(requestOptions);
};

export default Object.freeze({
	getAccessToken,
	executeApi,
	clearAccessToken,
	setAccessToken,
	hasAccessToken
});

import * as constants from './constants';
import * as model from './model';
import * as selectors from './selectors';

import reducer from './reducer';

export default {
	constants,
	model,
	reducer,
	selectors
};

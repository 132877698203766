import { combineReducers } from 'redux';

import authMembers from './modules/auth';
import { AuthState } from './modules/auth/model';
import fundraiserMembers from './modules/fundraiser';
import { FundraiserState } from './modules/fundraiser/model';
import serviceMembers from './modules/service';
import { ServiceState } from './modules/service/model';

export interface AppState {
	readonly [fundraiserMembers.constants.STATE_KEY]: FundraiserState;
	readonly [serviceMembers.constants.STATE_KEY]: ServiceState;
	readonly [authMembers.constants.STATE_KEY]: AuthState;
}

/**
 * While the createStore method ends up creating an AppStore, we want to require that the initial
 * state is primed with a service state.  This helps us create an abstraction of where
 * these reducers is used and how requests can be made.  (Mobile uses different service lib than desktop)
 */
export interface InitialState {
	readonly [serviceMembers.constants.STATE_KEY]: ServiceState;
}

export default combineReducers({
	[fundraiserMembers.constants.STATE_KEY]: fundraiserMembers.reducer,
	[serviceMembers.constants.STATE_KEY]: serviceMembers.reducer,
	[authMembers.constants.STATE_KEY]: authMembers.reducer
});

import React, { Component } from 'react';
import styles from './fundraisingCarousel.module.scss';
import { Carousel } from 'react-responsive-carousel';

class FundraisingCarousel extends Component {
	render() {
		const settings = {
			showThumbs: false,
			showArrows: true,
			showStatus: false,
			showDots: false,
			infiniteLoop: true,
			interval: 10000,
			autoPlay: true
		};

		return (
			<div className={styles.carouselContainer}>
				<Carousel {...settings}>
					<div>
						<img alt={'The Animal Connection'} src="https://picsum.photos/675/360?image=110" />
						<div className={styles.legend}>
							<div className={styles.legendLabel}>The Animal Connection</div>
							<div className={styles.spacer} />
							<div className={styles.donate}>Donate</div>
						</div>
					</div>
					<div>
						<img alt={'The Andals Zoo'} src="https://picsum.photos/675/360?image=111" />
						<div className={styles.legend}>
							<div className={styles.legendLabel}>The Andals Zoo</div>
							<div className={styles.donate}>Donate</div>
						</div>
					</div>
					<div>
						<img alt={'University of Rochester'} src="https://picsum.photos/675/360?image=112" />
						<div className={styles.legend}>
							<div className={styles.legendLabel}>University of Rochester</div>
							<div className={styles.donate}>Donate</div>
						</div>
					</div>
					<div>
						<img alt={'The Frankfort District'} src="https://picsum.photos/675/360?image=113" />
						<div className={styles.legend}>
							<div className={styles.legendLabel}>The Frankfort District</div>
							<div className={styles.donate}>Donate</div>
						</div>
					</div>
				</Carousel>
				<div className={styles.circle}>
					<div className={styles.initials}>HN</div>
				</div>
			</div>
		);
	}
}

export default FundraisingCarousel;

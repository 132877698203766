import React, { Fragment, PureComponent } from 'react';

import Header from '../../../component/header';
import PropTypes from 'prop-types';
import ReactUiForm from '../../../react-ui-blocks/form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import flow from 'lodash/flow';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

class ValidateToken extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
		confirmUserSignUp: PropTypes.func.isRequired,
		username: PropTypes.string
	};

	render() {
		const form = generateForm(this.props.username);

		const { classes } = this.props;

		return (
			<Fragment>
				<Header smallLogo={true} />
				<div className={classes.container}>
					<ReactUiForm handleSubmit={this.handleSubmit} elements={form} />
				</div>
			</Fragment>
		);
	}

	handleSubmit = ({ code, username }) => {
		this.props.confirmUserSignUp({ code, username });
	};
}

const generateForm = emailDefault => [
	{
		type: 'typography',
		props: {
			key: 'type',
			variant: 'body1',
			children: 'You should have received an email from us with your validation code. Please enter that below.'
		}
	},
	{
		key: 'username',
		type: 'textField',
		props: {
			defaultValue: emailDefault,
			margin: 'normal',
			label: 'Email Address',
			helperText: 'Your email address',
			name: 'username',
			fullWidth: true
		}
	},
	{
		key: 'code',
		type: 'textField',
		props: {
			margin: 'normal',
			label: 'Validation Token',
			helperText: 'This was emailed to you',
			name: 'code',
			fullWidth: true
		}
	},
	{
		type: 'button',
		key: 'submit',
		submitForm: true,
		label: 'submit',
		props: {
			variant: 'contained',
			color: 'primary'
		}
	}
];

const customStyles = theme => ({
	container: {
		margin: 'auto',
		width: '70%',
		border: '1px solid #f2f2f2',
		padding: '15px',
		borderRadius: '5px',
		backgroundColor: '#f2f2f2'
	},
	header: {
		textAlign: 'center'
	},
	formControl: {
		margin: theme.spacing.unit
	}
});

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({}, dispatch);
};

export default flow([
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withRouter,
	withStyles(customStyles)
])(ValidateToken);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LinkList from '../linkList';
import styles from './header.module.scss';

class Header extends Component {
	static propTypes = {
		smallLogo: PropTypes.bool
	};

	render() {
		const logoStyles = [styles.logo];
		if (this.props.smallLogo) {
			logoStyles.push(styles.small);
		} else {
			logoStyles.push(styles.large);
		}

		return (
			<div className={styles.container}>
				<LinkList
					elements={[
						<Link key="code" to={'/code'}>
							Enter a 4giving code
						</Link>,
						<Link key="sign-in" to={'/signIn'}>
							Sign In
						</Link>
					]}
				/>
				<Link to={'/'}>
					<div className={logoStyles.join(' ')} />
				</Link>
				<h2 className={styles.heading}>Fundraise the easy way</h2>
			</div>
		);
	}
}

export default Header;

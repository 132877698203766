import { Reducer } from 'redux';

import { Action } from '../../common/typesInterface';
import ActionTypes from './actionTypes';
import { FundraiserState } from './model';

export const initialState: FundraiserState = {
	fundraiser: {},
	fundraiserList: [],
	status: {
		error: undefined,
		inFlight: false,
		success: false
	}
};

const reducer: Reducer<FundraiserState> = (state = initialState, action: Action): FundraiserState => {
	switch (action.type) {
		case ActionTypes.FUNDRAISER_REQUEST:
		case ActionTypes.FUNDRAISER_LIST_REQUEST:
			return {
				...state,
				status: {
					...state.status,
					inFlight: true,
					error: undefined
				}
			};
		case ActionTypes.FUNDRAISER_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				fundraiser: {} // cleanr fundraiser on success
			};
		case ActionTypes.FUNDRAISER_LIST_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				fundraiserList: action.data
			};
		case ActionTypes.SET_FUNDRAISER_VALUES:
			return {
				...state,
				fundraiser: {
					...state.fundraiser,
					...action.data
				}
			};
		case ActionTypes.FUNDRAISER_FAILURE:
		case ActionTypes.FUNDRAISER_LIST_FAILURE:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					error: action.error
				}
			};
		case ActionTypes.FUNDRAISER_STATUS_RESET:
			return {
				...state,
				status: {
					...initialState.status
				}
			};
		default: {
			return state;
		}
	}
};

export default reducer;

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import authMembers from '../store/modules/auth';
import AppContainer from './AppContainer.tsx';
import history from './history';
import PrivateRoute from './privateRoute';
import routes from './routes';
import privateRoues from './routes/private';

const router = props => {
	return (
		<Router history={history}>
			<Route
				render={routeProps => {
					return (
						<AppContainer routeProps={routeProps}>
							<Switch>
								{Object.values(routes).map(route => {
									return <Route exact key={route.route} path={route.route} render={route.getComponent} />;
								})}
								{Object.values(privateRoues).map(route => {
									return (
										<PrivateRoute
											// eslint-disable-next-line react/prop-types
											isAuthenticated={props.isAuthenticated}
											exact
											key={route.route}
											path={route.route}
											component={route.getComponent}
										/>
									);
								})}
								<Redirect from="*" to="/" />
							</Switch>
						</AppContainer>
					);
				}}
			/>
		</Router>
	);
};

const mapStateToProps = state => {
	return {
		isAuthenticated: authMembers.selectors.selectIsAuthenticated(state)
	};
};

export default connect(mapStateToProps)(router);

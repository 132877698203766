import * as actions from './actions';
import * as constants from './constants';
import * as model from './model';
import reducer from './reducer';
import * as selectors from './selectors';

export default {
	actions,
	constants,
	model,
	reducer,
	selectors
};

import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';

export const loginRequest = (): Action => ({
	type: t.LOGIN_REQUEST
});

export const loginSuccess = (): Action => ({
	type: t.LOGIN_SUCCESS
});

export const loginError = (error: Error): Action => ({
	type: t.LOGIN_ERROR,
	error
});

export const logoutSuccess = (): Action => ({
	type: t.LOGOUT_SUCCESS
});

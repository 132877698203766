import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import LoaderButton from '../../../component/loaderButton/loaderButton';

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		padding: theme.spacing.unit * 2,
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200
	}
});

class Test extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
		signUp: PropTypes.func.isRequired,
		signIn: PropTypes.func
	};

	state = {
		isLoading: false,
		email: '',
		password: '',
		confirmPassword: '',
		confirmationCode: '',
		newUser: null,
		loginUsername: '',
		loginPassword: ''
	};

	renderForm() {
		return (
			<div>
				<TextField
					className={this.props.classes.textField}
					id="standard-name"
					label="Email"
					value={this.state.name}
					onChange={this.handleChange('email')}
				/>
				<TextField
					className={this.props.classes.textField}
					type={'password'}
					id="standard-name"
					label="Password"
					value={this.state.name}
					onChange={this.handleChange('password')}
				/>
				<TextField
					className={this.props.classes.textField}
					type={'password'}
					id="standard-name"
					label="Confirm Password"
					value={this.state.name}
					onChange={this.handleChange('confirmPassword')}
				/>
				<LoaderButton type="submit" disabled={!this.validateForm()} label={'Sign In'} />
			</div>
		);
	}

	renderConfirmationForm() {
		return (
			<div>
				<TextField
					className={this.props.classes.textField}
					id="standard-name"
					label="Confirmation Code"
					value={this.state.confirmationCode}
					onChange={this.handleChange('confirmationCode')}
				/>
				<LoaderButton type="submit" isLoading={this.state.isLoading} label={'Verify'} />
			</div>
		);
	}

	renderGridTest() {
		const { classes } = this.props;
		return (
			<Grid container spacing={24}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>xs=12</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>xs=12 sm=6</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>xs=12 sm=6</Paper>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Paper className={classes.paper}>xs=6 sm=3</Paper>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Paper className={classes.paper}>xs=6 sm=3</Paper>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Paper className={classes.paper}>xs=6 sm=3</Paper>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Paper className={classes.paper}>xs=6 sm=3</Paper>
				</Grid>
			</Grid>
		);
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				{/*{this.renderGridTest()}*/}

				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							{this.state.newUser === null ? this.renderForm() : this.renderConfirmationForm()}
						</Paper>
					</Grid>
				</Grid>

				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<div>
								<TextField
									className={this.props.classes.textField}
									id="standard-name"
									label="Username"
									value={this.state.loginUsername}
									onChange={this.handleChange('loginUsername')}
								/>
								<TextField
									className={this.props.classes.textField}
									id="standard-name"
									label="Password"
									value={this.state.loginPassword}
									onChange={this.handleChange('loginPassword')}
								/>
								<LoaderButton type="submit" onClick={this.handleLogin} label={'Verify'} />
							</div>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}

	handleLogin = event => {
		event.preventDefault();
	};

	handleSubmit = async event => {
		event.preventDefault();
	};

	validateForm() {
		return (
			this.state.email.length > 0 && this.state.password.length > 0 && this.state.password === this.state.confirmPassword
		);
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};
}

export default flow([withStyles(styles)])(Test);

import React, { Fragment } from 'react';

import Footer from '../../component/footer';
import PropTypes from 'prop-types';
import styles from './publicContainer.module.scss';

const PublicContainer = props => {
	console.log('mounted');
	return (
		<Fragment>
			<div className={styles.container}>{props.children}</div>
			<Footer />
		</Fragment>
	);
};

PublicContainer.propTypes = {
	children: PropTypes.element
};

export default PublicContainer;

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';

import HorizontalRule from '../horizontalRule';

class WizardButtonGroup extends PureComponent {
	static propTypes = {
		advanceButtonDisabled: PropTypes.bool,
		advanceButtonLabel: PropTypes.string.isRequired,
		classes: PropTypes.shape({
			button: PropTypes.string.isRequired,
			actionsContainer: PropTypes.string.isRequired
		}).isRequired,
		handleBack: PropTypes.func,
		handleNext: PropTypes.func.isRequired,
		includeHR: PropTypes.bool,
		showBackButton: PropTypes.bool
	};

	static defaultProps = {
		advanceButtonDisabled: false,
		includeHR: true,
		showBackButton: true
	};

	render() {
		const {
			advanceButtonDisabled,
			advanceButtonLabel,
			classes,
			handleBack,
			handleNext,
			includeHR,
			showBackButton
		} = this.props;
		return (
			<Fragment>
				{includeHR && <HorizontalRule />}
				<div className={classes.actionsContainer}>
					{showBackButton && (
						<Button disabled={!handleBack} onClick={handleBack} className={classes.button}>
							Back
						</Button>
					)}
					<Button
						disabled={advanceButtonDisabled}
						variant="contained"
						color="primary"
						onClick={handleNext}
						className={classes.button}
					>
						{advanceButtonLabel}
					</Button>
				</div>
			</Fragment>
		);
	}
}

const styles = theme => ({
	button: {
		marginTop: theme.spacing.unit,
		marginRight: theme.spacing.unit
	},
	actionsContainer: {
		marginBottom: theme.spacing.unit * 2,
		marginTop: theme.spacing.unit * 2
	}
});

export default withStyles(styles)(WizardButtonGroup);

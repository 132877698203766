import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';

class FileUpload extends PureComponent {
	static propTypes = {
		handleDrop: PropTypes.func.isRequired,
		handleCancel: PropTypes.func.isRequired,
		helperText: PropTypes.string.isRequired
	};

	render() {
		const baseStyle = {
			width: 200,
			display: 'inline-block',
			borderWidth: 1,
			borderColor: '#1E1E1E',
			borderStyle: 'solid',
			textAlign: 'center',
			padding: 5,
			fontSize: 13,
			boxSizing: 'border-box',
			backgroundColor: 'white'
		};
		const activeStyle = {
			borderStyle: 'solid',
			borderColor: '#6c6',
			backgroundColor: '#eee'
		};
		const rejectStyle = {
			borderStyle: 'solid',
			borderColor: '#c66',
			backgroundColor: '#eee'
		};

		return (
			<Dropzone accept="image/*" onDrop={this.props.handleDrop} onFileDialogCancel={this.props.handleCancel}>
				{({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
					let styles = { ...baseStyle };
					styles = isDragActive ? { ...styles, ...activeStyle } : styles;
					styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

					return (
						<Fragment>
							<div {...getRootProps()} style={styles}>
								<input {...getInputProps()} />
								<div>Click or {isDragAccept ? 'Drop' : 'Drag'} here to upload...</div>
								{isDragReject && <div>Unsupported file type...</div>}
							</div>
							<Typography variant={'body2'} style={{ fontSize: '12px', paddingLeft: '50px' }}>
								{this.props.helperText}
							</Typography>
						</Fragment>
					);
				}}
			</Dropzone>
		);
	}
}

export default FileUpload;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './circleAction.module.scss';
import Grid from '@material-ui/core/Grid';

class CircleAction extends Component {
	static propTypes = {
		children: PropTypes.element,
		color: PropTypes.oneOf(['Red', 'Yellow', 'Blue', 'Orange', 'Green']).isRequired,
		label: PropTypes.string.isRequired,
		selected: PropTypes.bool
	};

	render() {
		const color = `${this.props.color.toLowerCase()}Circle`;

		const container = [styles.circleContainer];
		if (this.props.selected) {
			container.push(styles.selected);
		}

		return (
			<Grid item md={4} xs={12}>
				<div className={container.join(' ')}>
					<div className={styles[color]}>{this.props.children}</div>
					<div className={styles.circleLabel}>{this.props.label}</div>
				</div>
			</Grid>
		);
	}
}

export default CircleAction;
